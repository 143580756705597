import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import desinfection from "../Assets/desinfection.png";
import instrument from "../Assets/instrument.png";
import pansement from "../Assets/pansement.png";
import onlineshopping from "../Assets/onlineshopping.gif";
import logoCapvital from "../Assets/logo_capvital.gif";
import myLogo from '../Assets/balagne-medical.png';
import "../Styles/Professionnel.css";

  function Professionnel() {
    return (
      <div className="container" id="particuliers" >
        <div className="row justify-content-center">
          <div className="col-md-8">
            <Carousel fade>
              <Carousel.Item>
                <img className="professionnel-image" src={desinfection} alt="desinfection"/>
                <Carousel.Caption>
                  <h3>Désinfection et protection</h3>
                  <p>Lingettes & sprays pour la désinfection des instruments, draps, gants d’examens.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="professionnel-image" src={instrument} alt="instrument"/>
                <Carousel.Caption>
                  <h3>Instruments & appareils de diagnostic</h3>
                  <p>Ciseaux, pinces, bistouris, kits à usage unique, tensiomètres électroniques, otoscopes.</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="professionnel-image" src={pansement} alt="pansement"/>
                <Carousel.Caption>
                  <h3>Pansements</h3>
                  <p>Pour le soin des blessures, nous vous proposons une large gamme de désinfectants, et de pansement adaptés.</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
       <div className="shop-btn-container">
        <button
          className="text-appointment-btn"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "https://www.capvital.fr/catalogues/catalogue_mad_balagnemedicalservices.html";
          }}
        >
          <img src={onlineshopping} alt="onlineshopping" />Boutique
        </button>
        </div>
            
   <div className="partner-text">
            <img src={myLogo} alt="Balagne Medical Service" className="logo-bms" />
            <p>est partenaire de :</p>
          </div>

          <div className="logo-capvital-container">
            <img src={logoCapvital} alt="Logo Capvital" className="logo-capvital" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Professionnel; 
