import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link, NavLink } from "react-router-dom";
import myLogo from '../Assets/balagne-medical.png';

function Navbar() {
  const [nav, setNav] = useState(false);
  
  const mobileNavbarRef = useRef(null);
  const hamburgerIconRef = useRef(null);

  const openNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileNavbarRef.current && !mobileNavbarRef.current.contains(event.target) && !hamburgerIconRef.current.contains(event.target)) {
        setNav(false); 
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="navbar-section">
      <div className="logo">
        <Link to="/">
          <img src={myLogo} alt="Balagne Medical" paddingLeft={0} width={90} />
        </Link>
      </div>

      {/* Desktop Navbar */}
      <ul className="navbar-items">
        <li>
          <NavLink to="/" exact className="navbar-links" activeClassName="active">
            Accueil
          </NavLink>
        </li>
        <li>
          <NavLink to="/particuliers" className="navbar-links" activeClassName="active">
            Particuliers
          </NavLink>
        </li>
        <li>
          <NavLink to="/professionnels" className="navbar-links" activeClassName="active">
            Professionnels
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" className="navbar-links" activeClassName="active">
            Contact
          </NavLink>
        </li>
      </ul>

      {/* Mobile Navbar */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`} ref={mobileNavbarRef}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
          <li>
            <NavLink onClick={openNav} to="/" activeClassName="active">
              Accueil
            </NavLink>
          </li>
          <li>
            <NavLink onClick={openNav} to="/particuliers" activeClassName="active">
              Particuliers
            </NavLink>
          </li>
          <li>
            <NavLink onClick={openNav} to="/professionnels" activeClassName="active">
              Professionnels
            </NavLink>
          </li>
          <li>
            <NavLink onClick={openNav} to="/contact" activeClassName="active">
              Contact
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
          ref={hamburgerIconRef}
        />
      </div>
    </div>
  );
}

export default Navbar;
