import React, { useEffect, useState } from "react";
import store1 from "../Assets/store1.jpeg";
import phone from "../Assets/phone.gif";
import onlineshopping from "../Assets/onlineshopping.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import background1 from "../Assets/background1.mp4";
import "../Styles/View.css";

function View() {
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 200) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  useEffect(() => {
    const video = document.querySelector(".video-background");
    if (video) {
      video.playbackRate = 0.3;
    }
  }, []);


  return (
    <div className="section-container">
      <div className="View-section">

        <video className="video-background" autoPlay muted loop playsInline>
          <source src={background1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="text-section">
          <p className="text-headline">Balagne Medical Service</p>
          <h2 className="text-title">
            Spécialiste en matériel medical
          </h2>
          <p className="text-descritpion">
            Balagne Medical Service est une entreprise qui a été fondée en 2005. Notre équipe est spécialisée dans la location et la vente de matériel médical.
            Nous sommes en mesure de contribuer au bien-être des personnes âgées, des personnes à mobilité réduite, ou victime des accidents de la vie, en leur proposant un aménagement adapté de leur domicile.
          </p>

          <button
            className="text-appointment-btn"
            type="button"
            onClick={() => {
              window.location.href = 'tel:0495307856';
            }}
          > 
            <img src={phone} alt="phone icon" />Contact
          </button>

          <button
            className="text-appointment-btn"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              window.location.href="https://www.capvital.fr/catalogues/catalogue_mad_balagnemedicalservices.html";
            }}
          > 
            <img src={onlineshopping} alt="onlineshopping" />Magasin
          </button>
        </div>

        <div className="View-image-section">
          <img className="View-image1" src={store1} alt="store1" />

        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faAngleUp} />

      </div>
    </div>
  );
}

export default View;
