import React, { useState, useEffect } from "react";
import AproposCard from "./AproposCard";
import store2 from "../Assets/store2.png";
import deliveryTruck from "../Assets/delivery-truck.gif";
import wheelchair from "../Assets/wheelchair.gif";
import surgery from "../Assets/surgery.gif";
import fifty from "../Assets/fifty.gif";
import twenty from "../Assets/twenty.gif";
import myLogo from '../Assets/balagne-medical.png';
import logoCapvital from "../Assets/logo_capvital.gif";
import "../Styles/Apropos.css";
import ScrollToTop from "../Components/ScrollToTop.js";
import { Link } from "react-router-dom";

function Apropos() {
  const [patients, setPatients] = useState(0);
  const [startedCounting, setStartedCounting] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("apropos");
      const rect = section.getBoundingClientRect();

      if (rect.top <= window.innerHeight && !startedCounting) {
        setStartedCounting(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [startedCounting]);

  useEffect(() => {
    if (!startedCounting) return;

    const target = 15000;
    const duration = 3500;
    const stepTime = 50;
    const step = target / (duration / stepTime);

    let current = 0;
    const interval = setInterval(() => {
      current += step;
      if (current >= target) {
        clearInterval(interval);
        setPatients(target);
      } else {
        setPatients(Math.floor(current));
      }
    }, stepTime);

    return () => clearInterval(interval);
  }, [startedCounting]);

  return (
    <div className="Apropos-section" id="apropos">
      <ScrollToTop />

      <div className="Apropos-title-content">
        <h3 className="Apropos-title">
          <span>BMS c'est :</span>
        </h3>
        <p className="Apropos-description">
          Une entreprise capable d'intervenir auprès des particuliers, des professionnels de la santé, des collectivités, des hôpitaux, des unités de soins, et des EPHAD.
        </p>
      </div>
      
      <div className="text-stats" display="block">
        <div className="text-stats-container">
          <p>{patients}</p>
          <p>Patients accompagnés</p>
        </div>

        <div className="text-stats-container">
          <p><img src={fifty} alt="fifty" className="gif-size" /></p>
          <p>Professionnels de santé nous font confiance</p>
        </div>

        <div className="text-stats-container">
          <p><img src={twenty} alt="twenty" className="gif-size" /></p>
          <p>Années d'expériences</p>
        </div>
      </div>

      <div className="Apropos-cards-content">
        <AproposCard
          title="Matériels Particuliers"
          description="Location et vente de lits, de fauteuils roulants. Produits d'hygiène et incontinence, nutrition, confort et bien-être, produits d'allaitement."
          button={{
            label: 'Particuliers',
            to: '/particuliers',
            component: Link,
          }}
          img src={wheelchair} alt="wheelchair" />

        <AproposCard
          title="Matériels Professionnels"
          description="Désinfection et protection, matériels dédiés aux professionnels de santé, pansements."
          button={{
            label: 'Professionnels',
            to: '/professionnels',
            component: Link,
          }}
          img src={surgery} alt="surgery" />

        <AproposCard
          title="Livraison & Installation"
          description="Une équipe de livraison dédiée pouvant intervenir très rapidement, et mettre en place le matériel directement chez vous."
          img src={deliveryTruck} alt="delivery-truck" />
      </div>

      <div className="Apropos-image-section">
        <img className="Apropos-image2" src={store2} alt="store2" />
      </div>

       <div className="partner-text">
            <img src={myLogo} alt="Balagne Medical Service" className="logo-bms" />
            <p>est partenaire de :</p>
          </div>

          <div className="logo-capvital-container">
            <img src={logoCapvital} alt="Logo Capvital" className="logo-capvital" />
      </div>
    </div>
  );
}

export default Apropos;
